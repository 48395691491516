import http from "./http-common";
import axios from "axios";



let baseURL = `https://services4.wegosecure.com/gstzone/api/mygst`


const getClientList = (data) => {
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url = baseURL + `/clientDetails?pageNo=${data.pageNo}&pageSize=${data.pageSize}`
    return axios.get(url, Header_Axios);
}

const getReturnsList = (data) => {
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url = baseURL + `/returns?pageNo=${data.pageNo}&pageSize=${data.pageSize}`
    return axios.get(url, Header_Axios);
}

const getSearchList = (data) => {
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url = baseURL +  `/trackReturns?gstNo=${data.gstNo}&fy=${data.year} `
    return axios.get(url, Header_Axios);
}

const getCount = () => {
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url = baseURL +  `/apiCount`
    return axios.get(url, Header_Axios);
}

const getProgress = () => {
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url = baseURL +  `/progress`
    return axios.get(url, Header_Axios);
}

const getCsv = () => {
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url = baseURL +  `/csv`
    return axios.get(url, Header_Axios);
}

const getCsvTableData = () => {
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url =baseURL +  `/table/gstReturns`
    return axios.get(url, Header_Axios);
}


const getUserList=()=>{
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url =baseURL +  `/user/list`
    return axios.get(url, Header_Axios);
}

const saveUser=(data)=>{
    let getToken = JSON.parse(sessionStorage.getItem('token'))
    let Header_Axios = { 
        headers: {
            "Authorization" : `Bearer ${getToken}`,
            "Content-Type": "application/json",
        } 
    }
    let url =baseURL +  `/user/save`
    return axios.post(url, data,  Header_Axios);
}





export default {
    getClientList,
    getSearchList,
    getCount,
    getReturnsList,
    getProgress,
    getCsv,
    getCsvTableData,
    getUserList,
    saveUser
};

