import axios from "axios";

let getToken = JSON.parse(sessionStorage.getItem('token'))
export default axios.create({
  baseURL: 'https://services4.wegosecure.com/gstzone/api/mygst', 
  /* headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken}` //the token is a variable which holds the token
  } */
  /* "ngrok-skip-browser-warning": "69420", */
});
