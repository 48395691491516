import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import  Button  from "@mui/material/Button";
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

let initialState={
  backAgent: ``,
  salesAgent: '',
  clientName: '',
  gstNo: ''
}

function CreateOrEditFileing(props){
    //const [open, setOpen] = useState(props.open)
    const [value, setValue]= useState(initialState)

    useEffect(()=>{
      if(props.mode == 'Edit'){
        setValue(props.data)
      }
    },[])

    const handleTextInputChange = (event) => {
        console.log("event", event.target.value, )
        setValue(prev => ({...prev, [event.target.name]: event.target.value}))
    };

    const handleRegex=()=>{
      if(value.backAgent.length > 1 && 
        value.salesAgent.length > 1 && 
        value.clientName.length > 1 &&
        value.gstNo.length > 13){
          return true;
      }
    }

    const handleSubmit=()=>{
      const allowSubmit = handleRegex();
      if(allowSubmit){
        props.onSave(value)
      }     
    }

    return(
        <Dialog open={props.open} onClose={() => props.onClose()}>
          <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
                  <Typography variant="h5" gutterBottom>
                      {`${props.mode} ${'New Annual Filing'}`} 
                  </Typography>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
                <TextField id="backAgent" name="backAgent" label="Back Agent" variant="outlined" 
                fullWidth value={value.backAgent} onChange= {handleTextInputChange}/>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
                <TextField id="salesAgent" name="salesAgent" label="Sales Agent" variant="outlined" 
                fullWidth value={value.salesAgent} onChange= {handleTextInputChange}/>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
                <TextField id="clientName" name="clientName" label="Client Name" variant="outlined" 
                fullWidth value={value.clientName} onChange= {handleTextInputChange}/>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
                <TextField id="gstNo" name="gstNo" label="Gst Number" variant="outlined" 
                fullWidth value={value.gstNo} onChange= {handleTextInputChange}/>
            </Grid>
          </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.onClose()} color="primary">
              Close
            </Button>
            <Button onClick={() => handleSubmit()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditFileing