import React,{useState, useEffect, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, useBlockLayout } from "react-table";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TablePagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import ApiIcon from '@mui/icons-material/Api';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '../../Constants/MenuList';
import DataServices from '../../Services/DataServices';
import DownloadIcon from '@mui/icons-material/Download';
import Pagination from '@mui/material/Pagination';
import { CSVLink } from 'react-csv';
import { saveAs } from "file-saver";
import Papa from "papaparse";


const useStyles = makeStyles({
    table: {
      /* minWidth: 650,
      //backgroundColor: "lightgrey",
      border: "1px solid black" */
      width: `100%`,
      backgroundColor: `#fff`,
      color: `#333`,
      fontSize: `14px`,
      marginBottom:  `20px`,
    },
    tableBorder:{
        border: "1px solid lightgrey !important"
    },
    header: {
      fontWeight: "bold",
      color: "white",
      backgroundColor: "White",
      textAlign: `center !important`,
      borderBottom: "1px solid black",
    },
    cell: {
      borderBottom: "1px solid lightgrey",
      //padding: "10px"
    }
  });


  // Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
      <TextField
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    
  )
}


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <TextField
      fullWidth
      size="small"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}


let customCol = [
    {	
        Header: "BASIC DETAILS",
        columns: [
          {
            Header: "Gst No",
            accessor: "gstNo",
            id: 'gstNo',
          },
          {
            Header: "TradeName",
            accessor: "tradeName",
            id: 'tradeName'
          },
          {
            Header: "Agent",
            accessor: "agent",
            id: 'agent',

          },
          {
            Header: "Status",
            accessor: "status",
            id: 'status',
          
          },
        ],
      },
]

function MuiDashboard(){
    const classes = useStyles();
    const theme = useTheme();
    const [periodData, setPeriodData] = useState(MenuList[0].value)
    const [gstData, setGstData] = useState("")
    const [col, setCol]= useState(customCol);    
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [listening, setListening] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [pageIndex, setPageIndex]= useState(0);
    const [page, setPage] = React.useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [search, setSearch] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const pageSizeValue = 10;
    let eventSource;
    const csvLink = useRef();
    const [bDownloadReady, setDownloadReady] = useState(false);

    const columns = useMemo(
        () => col,
        [col]
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )

    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
         state: {  pageSize },
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
      } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: { pageSize: 100 },
        manualPagination: true,
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      useBlockLayout
      );


      useEffect(()=>{
       getApiCount();
      },[])



      const getApiCount=async()=>{
        await DataServices.getCount().then((res)=>{
         // console.log("Count", res)
          let val = res && res.data && res.data.data && res.data.data.count;
          setCount(val)
        }).catch((e)=>{
          console.log("err", e)
        })
      }

      useEffect(() => {   
        setSearch(false)    
        setLoading(true);
        let array = []
        if(!listening ){
            eventSource = new EventSource(`https://services4.wegosecure.com/gstzone/api/mygst/gst/returns?pageNo=${pageIndex}&pageSize=${pageSizeValue}`,);
            eventSource.onopen = (event) => {
            console.log("connection opened")
            }           
            
            eventSource.onmessage = (event) => {
            let d = event && event.data && JSON.parse(event.data)
            console.log("result", d);
            let valid = data && data.length == 0;
            if(initialLoad && valid){
                let duplicateCol = [...col]
                d && d.data && Object.entries(d.data.returns).map(([key,value],i)=>{
                    let childCol = [] 
                    value.length>0 && value.map((x, y)=>{
                        //console.log(d.data.returns[key][y].status)
                        //console.log("va", x, y)
                        let child = { 
                            Header: x.rtntype,
                            accessor: `returns[${key}][${y}].status`,
                            id:  key + x.rtntype,
                            Cell: ({ value }) => {
                                let x =  value === 'Filed' ? `#b7e1cd` : value === 'NotFiled' ? `#f4cccc` : (value === undefined) ? `#fff176` : `none`
                                return <div style={{backgroundColor: x, height: '100%', width: '100%'}}>
                                  {value ? value : `nill`}</div>;
                            },                         
                        }
                        childCol.push(child)
                    })
                    let addCol = {
                        Header: key,
                        columns: childCol[0].Header == "GSTR3B" ? childCol.reverse() : childCol,
                    }
                    duplicateCol.push(addCol);
                })
                setCol(duplicateCol);
                setTotalData(d.data.totalRecords)
                setTotalPages(d.data.totalPages)
            }
            array = [...array,  d.data]
            updateRowData(array);
            setLoading(false);
            setPageCount(pageIndex)
            setInitialLoad(false);
            }
            
            eventSource.onerror = (event) => {
            console.log(event.target.readyState)
            if (event.target.readyState === EventSource.CLOSED) {
                console.log('eventsource closed (' + event.target.readyState + ')')
            }
            eventSource.close();
            }
          
            setListening(true)
        }

        return () => {
            eventSource.close();
            console.log("eventsource closed")
          }
      }, [pageIndex, pageSize]);

      const updateRowData = useCallback((newData) => {
        setData(newData); 
        }, []);


      const handlePageIndex=async(event, value)=>{
        console.log("vv", value)
        setPage(value)
        setPageIndex(value-1);
        setData([]);
        setListening(false)
      }

      const isLoadingComponent =(msg)=>{
        return(
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid Item style={{alignContent: `center`, justifyContent: `center`}}>
              <CircularProgress />
              <p style={{color: theme.palette.primary.main, textAlign: `center`, 
              padding: `10px`, marginLeft: `-60px`}}>{msg}</p>
              </Grid>
            </Grid>
        );
      }


  const handleTextInputChange = event => {
    setPeriodData(event.target.value);
  };

  const onReset=()=>{    
    setPeriodData(MenuList[0].value);
    setGstData('')  
    setListening(false)
    setPageIndex(0)  
    updateRowData([]) 
  }

  const handleGstInputChange = event => {
      setGstData(event.target.value);
  };

  const getSearchedDetails=async()=>{   
    setLoading(true)
    setSearch(true)
    if(gstData.length > 0){
        let data = {
          gstNo: gstData,
          year: periodData
        }
        await DataServices.getSearchList(data).then((res)=>{
          let val = res && res.data && res.data.data; 
          updateRowData([val])
        }).catch((e)=>{
          console.log("err", e)
        })
    }
    setLoading(false)
  }

  


    const SearchBar=()=>{
        return(
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4} md={3}>
                            <TextField
                              id="outlined-select-currency"
                              select
                              fullWidth
                              label="Select"
                              value= {periodData}
                              onChange= {handleTextInputChange}
                          
                            >
                              {MenuList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={4} md={6}>
                            <TextField 
                            label="Enter Gst Number" 
                            value= {gstData}
                            onChange= {handleGstInputChange}
                            fullWidth/>
                          </Grid>
                          <Grid item xs={12} sm={4} md={3} style={{ display: `flex`, flexDirection: `row`, justifyContent: `space-evenly`}}>
                              <Button variant="contained" onClick={()=>getSearchedDetails()} >Search your gst</Button>
                              <Button variant="contained" color="secondary"onClick={()=> onReset()} >Reset</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>        
        );
    } 

    const csvHeaders =  headerGroups.map(headerGroup => (
      headerGroup.headers.map((column) => ({
        label: column.Header,
        key: column.id,
      }))
    ));  

    const downloadExcel = (actionType) => {
      console.log("downloadexcel")
      
     /*  const csvData = Papa.unparse({
        fields: columns.map((column) => column.Header),
        data: data.map((row) =>
          columns.map((column) => {
            console.log(column)
            if (column.columns) {
              return column.columns.map((subColumn) =>{
                  console.log(subColumn)
                  console.log(subColumn.accessor.split("."))
                  subColumn.accessor && subColumn.accessor.split(".").reduce((obj, key) =>subColumn.Header, row)
                }
              );
            }
            return column.accessor && column.accessor.split(".").reduce((obj, key) => column.Header, row);
          })
        ),
      });
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      saveAs(blob, `${1}.csv`); */
      
    }

    
    return (       
        <>
        {SearchBar()}<br />
        <Grid
          container
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item> 
              <Tooltip title={`${count} Count`}>
                <IconButton aria-label="delete" size="medium">
               
                  <Badge badgeContent={count} color="secondary" max={1000000}>
                    <ApiIcon color="primary" />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={`Export`}>
              
                <IconButton aria-label="delete" size="medium" onClick={()=> downloadExcel("DOWNLOAD")}>                        
                    <DownloadIcon fontSize="inherit" color='primary' />                                
                </IconButton>
              
              </Tooltip>
          </Grid>
          
      </Grid>

        {loading  ? isLoadingComponent("Fetching data please wait...") 
         : data && data.length  > 0 ? (
          <Card>
        <TableContainer>
            <Table {...getTableProps()}  className={classes.table} >
            <TableHead className={classes.tableBorder}>
                {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (  
                      <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} 
                      className={`${classes.header}${column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : ""}`}
                      >                   
                        {column.render("Header")}<br /><br />
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                      </TableCell>
                    ))}
                </TableRow>
                ))}
            {/*     <TableRow>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                </TableRow> */}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                prepareRow(row);
                return (
                    <TableRow {...row.getRowProps()} className={classes.tableBorder}>
                    {row.cells.map(cell => {
                    /*     { console.log("cell", cell) } */
                        return <TableCell {...cell.getCellProps()}                         
                        className={classes.cell}
                       /*  style={{
                          backgroundColor: cell.value === 'Filed' ? `#b7e1cd` : cell.value === 'NotFiled' ? `#f4cccc` : (cell.value === 'nill' || cell.value === undefined) ? `#fff176` : `none`,
                        
                        }} */
                        >{cell.render("Cell")}</TableCell>;
                    })}
                    </TableRow>
                );
                })}
                {!search && data.length < pageSizeValue -1 && (
              <TableRow>
                <TableCell colSpan={columns.length} style={{ color: theme.palette.primary.main,
                textAlign: `center`}}>
                  <CircularProgress /> 
                  <p>Loading more data...</p>
                </TableCell>
              </TableRow>
            )}
            </TableBody>
            </Table>
        </TableContainer>
       {/*  <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                count={pageSize}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => setPageSize(Number(event.target.value))}
                ActionsComponent={() => (
                    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                    <IconButton
                      //onClick={gotoPage(0)}
                      onClick={() => handlePageIndex(0)}
                      aria-label="first page"
                    >
                      {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton
                      onClick={() => handlePageIndex(pageIndex-1)}
                      aria-label="previous page"
                    >
                      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton
                      onClick={() =>handlePageIndex(pageIndex+1)}
                      aria-label="next page"
                    >
                      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton
                      //onClick={handleLastPageButtonClick}
                      aria-label="last page"
                    >
                      {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                  </Box>
                )}
            /> */}
            <Grid
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item> 
                <Pagination count={totalPages} page={page} onChange={handlePageIndex} />
              </Grid>
            </Grid>
            
        </Card>
        ) : null}
        </> 
      );
 
}

export default MuiDashboard


/*
<div>
    <button onClick={() => previousPage()} >
    {"<"}
    </button>{" "}
    <button onClick={() => nextPage()} >
    {">"}
    </button>{" "}
</div>
*/