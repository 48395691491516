import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HomeIcon from '@mui/icons-material/Home';
import Dashboard from '../Components/Dashboard/Dashboard';
import TableComponent from '../Components/Dashboard/TableComponent';
import MyTable from '../Components/Dashboard/myTable';
import AnnualFilling from '../Components/AnnualFilling/AnnualFilling';
import MyGrid from '../Components/testPagination';
import NewPagination from '../Components/NewPagination';
import MuiDashboard from '../Components/MuiTable/MuiDashboard';
import FileingComponent from '../Components/Datatable/FileingComponent';
import NewGst from '../Components/Datatable/NewGst'

const appRoutes = [
    /* {
        index: true,
        element: <Home />,
        state: "dashboard"
    }, */
    {
        path: "/annual-filling",
        element: <NewGst />,
        state: "annualFilling",
        sidebarProps: {
          displayText: "Annual Filling",
          icon: <DashboardOutlinedIcon />
        }
    },
    {
      path: "/client-details",
      element: <AnnualFilling />,
      state: "clientDetails",
      sidebarProps: {
        displayText: "Client Details",
        icon: <ArticleOutlinedIcon />
      }
    }, 
    /* {
      path: "/grid",
      element: <FileingComponent />,
      state: "grid",
      sidebarProps: {
        displayText: "Grid",
        icon: <ArticleOutlinedIcon />
      }
    },  */
    /* {
      path: "/table",
      element: <MyTable />,
      state: "Table",
      sidebarProps: {
        displayText: "Table",
        icon: <ArticleOutlinedIcon />
      }
    },  */
    /* {
      path: "/form",
      element: <FillForm />,
      state: "form",
      sidebarProps: {
        displayText: "Challan Form",
        icon: <ArticleOutlinedIcon />
      }
    }, */
    /* {
        path: "/home",
        element: <Home />,
        state: "home",
        sidebarProps: {
          displayText: "Home",
          icon: <HomeIcon />
        }
    }, */
];

export default appRoutes;