import React,{useState} from "react";
import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../Routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarCollapse";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../Assets/gst1.png';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';



const Sidebar = (props) => {
   
    return (
      <Drawer
        variant="permanent"
        sx={{
          width: props.open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: props.open ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.xsWidth,
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.color
          }
        }}
      >
        <List >
            <Toolbar sx={{ marginBottom: "20px" }}>
            {props && props.open &&(
                  <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="center"
                  >
                  <IconButton sx={{color: colorConfigs.sidebar.color}} onClick={props.changeEvent}>
                   {/*  <KeyboardArrowRightIcon  style={{marginRight: `-200px`}}/>  */}
                   <img src={logo} width={300} height={140} style={{padding: `50px`}}/>
                 {/*  <MenuIcon /> */}
                  </IconButton>
                  </Stack>
            )}
            {props && !props.open &&(
              <Stack
                  sx={{ width: "100%" }}
                  direction="row"
                  justifyContent="center"
                  >
                  <IconButton sx={{color: colorConfigs.sidebar.color}} onClick={props.changeEvent}>
                      <MenuIcon />
                  </IconButton>
                </Stack>
            )}           
        </Toolbar>
          {appRoutes.map((route, index) => (
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          ))}
        </List>
      </Drawer>
    );
  };
  
  export default Sidebar;