import CircularProgressWithLabel from './ProgressComponent';
import RefreshIcon from '@mui/icons-material/Refresh';
import Snackbar from '@mui/material/Snackbar';
import DownloadIcon from '@mui/icons-material/Download';
import Menu from '@mui/material/Menu';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Divider, IconButton } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import ApiIcon from '@mui/icons-material/Api';
import Badge from '@mui/material/Badge';
import React,{useState, useEffect, useMemo, useCallback, useRef } from 'react';
import DataServices from '../../Services/DataServices';
import MenuItem from '@mui/material/MenuItem';



function CustomIconComponent(props){
    console.log("eee", props)
    const [progress, setProgress] = React.useState(0);
    const [downloadStatus, setDownloadStatus]= useState(false)
    const [progressBar, setProgressBar]= useState(true)
    const [openAlert, setOpenAlert]= useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [initialLoad, setInitialLoad]= useState(true)
    const [lastUpdateDate, setLastUpdateDate]= useState('')
  
    const handleMenuChange = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    let interval; 
  
    useEffect(() => {
      //console.log('sss', downloadStatus)
      interval = setInterval(() => {
        // setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        //console.log('ss111s')
        if(downloadStatus){
          getProgressInfo(true);
          //console.log('sss')
        } 
      
      }, 2000);
    
      return () => clearInterval(interval);
    }, [downloadStatus]);
  
    useEffect(() => {
      getProgressInfo(false);
    },[])
  
    const getProgressInfo=async(allow)=>{
    await DataServices.getProgress().then((res)=>{
    let val = res && res.data && res.data.data && res.data.data.progress;
    //console.log(val)
    let lastDate =  res && res.data && res.data.data && res.data.data.createdOn;
    let lastValue = lastDate //lastDate && lastDate.split('.')[0].split('s').pop();
    let download = res && res.data && res.data.data && res.data.data.filePath;
    if(allow && download != null){
        //console.log("download", download)
        //window.open(download, "_blank")
        props.callBackFn(true);
        DownloadFile(download)
    }
    setProgressBar(false)
    setProgress(val)
    setInitialLoad(false)
    setLastUpdateDate(lastValue)
    }).catch((e)=>{
    console.log("err", e)
    setProgressBar(true)
    })
    }

    const DownloadFile = (filePath)=>{
    if(filePath != null){
        var link=document.createElement('a');
        link.href = filePath;
        link.download = filePath;
        link.click();
        setDownloadStatus(false)
        clearInterval(interval);
    }      
    }

    const downloadCsv=async()=>{     
        await DataServices.getCsv().then((res)=>{
        //console.log(res)
        setDownloadStatus(true)
        setOpenAlert(true)
        }).catch((e)=>{
        console.log("err", e)
        setOpenAlert(false)
        })

    }
  
     
  
      return(
      <React.Fragment>
         
         <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleMenuChange}
        >
         <DownloadIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={()=>downloadCsv()}>
              <IconButton  >
                <CircularProgressWithLabel value={progress} />  
              </IconButton> 
              Generate Report
          </MenuItem>
          <MenuItem onClick={()=>getProgressInfo(true)}>
              <IconButton>
                <DownloadIcon />
              </IconButton> 
              Download
          </MenuItem>
        {lastUpdateDate && (
          <MenuItem disabled={true} style={{display: `flex`, justifyContent: `center`, textAlign: `center`, }}>
            <IconButton>
                <AccessTimeIcon />
              </IconButton> 
            
            <span>Report generated on {lastUpdateDate}</span>
          </MenuItem>
        )}
        </Menu>
  
          <Tooltip title={`${props.count} Count`}>
            <IconButton >
              <Badge badgeContent={props.count} color="secondary" max={1000000}>
                      <ApiIcon color="primary" />
              </Badge>
            </IconButton>
          </Tooltip>
  
          {openAlert &&(
                <>
                 <Snackbar
                    anchorOrigin={{   vertical:'bottom',  horizontal: 'right' }}
                    open={downloadStatus}
                    onClose={()=> openAlert(false)}
                    message="File will download once progress reached 100%"                  
                  />
                </>
          )}
        </React.Fragment>
      )
}

export default CustomIconComponent;