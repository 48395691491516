import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: colors.blueGrey["700"],
    color: "#fff",
    hoverBg: colors.purple["400"],
    activeBg: colors.purple["600"],
  },
  topbar: {
    bg: "#fff",
    color: "#000"
  },
  mainBg: colors.grey["100"]
};

export default colorConfigs;