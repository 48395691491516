const MenuList = [
    {
      value: '2022-23',
      label: '2022-2023',
    },
    {
      value: '2021-22',
      label: '2021-2022',
    },
    {
      value: '2020-21',
      label: '2020-2021',
    }
];

export default MenuList
