import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from 'react-router-dom';

const sessionDetails = () => {
    return JSON.parse(sessionStorage.getItem(`token`));
  };

const PrivateRoute = ({ children }) => {
    const [auth, setAuth] = useState(false);

    const tokenData = sessionDetails();
    return tokenData ? (children ): <Navigate to="/" />;
}

export default PrivateRoute;