import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyB5TeiBg3Fo8fNnbKj4r5Cptc_mPAEX5UA",
  authDomain: "mygst-328d2.firebaseapp.com",
  projectId: "mygst-328d2",
  storageBucket: "mygst-328d2.appspot.com",
  messagingSenderId: "531441570509",
  appId: "1:531441570509:web:ae222c20ec562afc3f4f84",
  measurementId: "G-ENHWZCT8BQ"
}; 

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
 

export default firebaseApp;

