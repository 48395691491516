import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./Controller/LayoutController/MainLayout";
import { routes } from "./Routes";
import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material';
import { CssBaseline} from "@mui/material";
import { lightTheme, darkTheme } from "./theme";
import Login from "./Authentication/Login";
import SignUp from "./Authentication/SignUp";
import PrivateRoute from "./PrivateRoute";


function App() {
  const [light, setLight] = React.useState(true);
  return (
    <React.Fragment>
      <CssBaseline enableColorScheme={true}/>
         <ThemeProvider theme={light ? lightTheme : darkTheme}>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/" element={<PrivateRoute><MainLayout /></PrivateRoute>}>
          {routes}
        </Route>
      </Routes>
    </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
