import * as React from 'react';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import colorConfigs from '../configs/colorConfigs';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import logo from '../Assets/gst1.png'
import Link from '@mui/material/Link';
import DataServices from '../Services/DataServices';
import ToastController from '../Controller/ToastController/ToastController';
import {auth} from '../firebaseConfig';
import { signInWithEmailAndPassword    } from "firebase/auth";

function Login(){
    const navigate = useNavigate();
    const theme = createTheme();
    const [successBox, setSuccessBox] = React.useState(false);
    const [toastMsg, setToastMsg] = React.useState({
        msg: '',
        status: ''
    });

    const handleSubmit = async(event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          email: data.get('email'),
          password: data.get('password'),
        });
        let loginCredentials = {
            "userName": data.get('email'),
            "password": data.get('password')
        }
        await signInWithEmailAndPassword(auth, loginCredentials.userName, loginCredentials.password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            sessionStorage.setItem('token', JSON.stringify(user.accessToken));
           
            navigate("/annual-filling")
           
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;            
            setToastMsg({
                msg: 'Kindly check your user credentials',
                status: `error`
            })   
            setSuccessBox(true)  
            // ..
        });
    };

    const onClose = (e) => {
        setSuccessBox(false)
    }

    return(
       <Grid container 
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{backgroundColor: colorConfigs.sidebar.bg, height: `100vh`}}>
            <Grid item>
                <Card sx={{ maxWidth: 500 }}>
                    <CardContent>
                        <Box
                            sx={{
                                marginTop: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            >
                                <img src={logo} width={300} height={70}/>
                              
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                />
                                <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                />
                                
                                <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                >
                                Sign In
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2" color="secondary">
                                        Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/signup" variant="body2" color="secondary">
                                        {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            {successBox && (
                <ToastController
                    isOpen={successBox}
                    onClose={onClose}
                    message={toastMsg.msg}//{toastMsg.msg}
                    status={toastMsg.status}//{toastMsg.status}
                />
            )}

       </Grid>
    );
}
export default Login