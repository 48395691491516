import React,{useState, useEffect, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import Download from '@mui/icons-material/Download';
import { Divider, IconButton } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import ApiIcon from '@mui/icons-material/Api';
import Badge from '@mui/material/Badge';
import DataServices from '../../Services/DataServices';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '../../Constants/MenuList';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgressWithLabel from './ProgressComponent';
import RefreshIcon from '@mui/icons-material/Refresh';
import Snackbar from '@mui/material/Snackbar';
import DownloadIcon from '@mui/icons-material/Download';
import Menu from '@mui/material/Menu';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function CustomToolbarComponent(props){
  const [progress, setProgress] = React.useState(0);
  const [downloadStatus, setDownloadStatus]= useState(false)
  const [progressBar, setProgressBar]= useState(true)
  const [openAlert, setOpenAlert]= useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [initialLoad, setInitialLoad]= useState(true)
  const [lastUpdateDate, setLastUpdateDate]= useState('')

  const handleMenuChange = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let interval; 

  useEffect(() => {
    //console.log('sss', downloadStatus)
    interval = setInterval(() => {
      // setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      //console.log('ss111s')
      if(downloadStatus){
        getProgressInfo(true);
        //console.log('sss')
      } 
    
    }, 2000);
  
    return () => clearInterval(interval);
  }, [downloadStatus]);

  useEffect(() => {
    getProgressInfo(false);
  },[])




    const getProgressInfo=async(allow)=>{
      await DataServices.getProgress().then((res)=>{
      let val = res && res.data && res.data.data && res.data.data.progress;
      //console.log(val)
      let lastDate =  res && res.data && res.data.data && res.data.data.createdOn;
      let lastValue = lastDate //lastDate && lastDate.split('.')[0].split('s').pop();
      let download = res && res.data && res.data.data && res.data.data.filePath;
      if(allow && download != null){
        //console.log("download", download)
        //window.open(download, "_blank")
        DownloadFile(download)
      }
      setProgressBar(false)
      setProgress(val)
      setInitialLoad(false)
      setLastUpdateDate(lastValue)
      }).catch((e)=>{
      console.log("err", e)
      setProgressBar(true)
      })
    }

    const DownloadFile = (filePath)=>{
      if(filePath != null){
        var link=document.createElement('a');
        link.href = filePath;
        link.download = filePath;
        link.click();
        setDownloadStatus(false)
        clearInterval(interval);
      }      
    }

    const downloadCsv=async()=>{     
       await DataServices.getCsv().then((res)=>{
          //console.log(res)
          setDownloadStatus(true)
          setOpenAlert(true)
          }).catch((e)=>{
          console.log("err", e)
          setOpenAlert(false)
        })

    }

   

    return(
    <React.Fragment>
       
       <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuChange}
      >
       <DownloadIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>downloadCsv()}>
            <IconButton  >
              <CircularProgressWithLabel value={progress} />  
            </IconButton> 
            Generate Report
        </MenuItem>
        <MenuItem onClick={()=>getProgressInfo(true)}>
            <IconButton>
              <DownloadIcon />
            </IconButton> 
            Download
        </MenuItem>
      {lastUpdateDate && (
        <MenuItem disabled={true} style={{display: `flex`, justifyContent: `center`, textAlign: `center`, }}>
          <IconButton>
              <AccessTimeIcon />
            </IconButton> 
          
          <span>Report generated on {lastUpdateDate}</span>
        </MenuItem>
      )}
      </Menu>

        <Tooltip title={`${props.count} Count`}>
          <IconButton >
            <Badge badgeContent={props.count} color="secondary" max={1000000}>
                    <ApiIcon color="primary" />
            </Badge>
          </IconButton>
        </Tooltip>

        {openAlert &&(
              <>
               <Snackbar
                  anchorOrigin={{   vertical:'bottom',  horizontal: 'right' }}
                  open={downloadStatus}
                  onClose={()=> openAlert(false)}
                  message="File will download once progress reached 100%"                  
                />
              </>
        )}
      </React.Fragment>
    )
}




function FileingComponent(){
    let eventSource;
    const [data, setData] = useState([]);
    const [pageIndex, setPageIndex]= useState(0);
    const [pageSize, setPageSize]= useState(10);
    const [totalData, setTotalData] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [listening, setListening] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [duplicateData,  setDuplicateData] =  useState([]);
    const initialColumn = [
      {
        name: "s.no",
        label: "S/No",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log(value, tableMeta, updateValue)
            return(
                <span>{(tableMeta.tableState.page * tableMeta.tableState.rowsPerPage) + tableMeta.rowIndex + 1}</span>
              )
          }
        }
      },
      {
          name: "gstNo",
          label: "GST No",
          options: {
          filter: true,
          sort: true
          }
      },
      {
          name: "tradeName",
          label: "Trade Name",
          options: {
          filter: true,
          sort: true
          }
      },
      {
          name: "agent",
          label: "Agent",
          options: {
          filter: true,
          sort: true
          }
      },
      {
          name: "status",
          label: "Status",
          options: {
          filter: true,
          sort: true
          }
      },
    ]
    const [column, setColumn] = useState(initialColumn);
    const [count, setCount] = useState(0);
    const [periodData, setPeriodData] = useState(MenuList[0].value)
    const [gstData, setGstData] = useState("")
    const [search, setSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    

    const [page, setPage] = useState(0);

    const pageSizeValue = 10;

   
    useEffect(()=>{
        getApiCount();
        getTableData();
       },[])

    const getApiCount=async()=>{
        await DataServices.getCount().then((res)=>{
        let val = res && res.data && res.data.data && res.data.data.count;
        setCount(val)
        }).catch((e)=>{
        console.log("err", e)
        })
    }

    const getTableData=async()=>{
      await DataServices.getCsvTableData().then((res)=>{
        let val = res && res.data && res.data.data; 
        console.log("getTableData", val)    
      }).catch((e)=>{
      console.log("err", e)
      })
  }
 

    useEffect(() => {   
        //setLoading(true);
        //console.log("useEffect", pageIndex, pageSize)
        let array = []
        if(!listening ){
            eventSource = new EventSource(`https://services4.wegosecure.com/gstzone/api/mygst/gst/returns?pageNo=${pageIndex}&pageSize=${pageSize}`,);
            eventSource.onopen = (event) => {
            console.log("connection opened")
            }           
            
            eventSource.onmessage = (event) => {
            let d = event && event.data && JSON.parse(event.data)
            //console.log("result", d);
            let getRows = [...data]
            let valid = data && data.length == 0;
            let childRow = [] 
            let childCol = [...column] 
                
            let cRow = {}    
            d && d.data && Object.entries(d.data.returns).map(([key,value],i)=>{
                                
            {value.length>0 && value.map((x, y)=>{
                if(initialLoad && valid){
                    let l = x.month.split(' ')
                    //console.log(l)
                    //console.log(l[0])
                    let cCol = {
                        name:  `${x.month}` + `${x.rtntype}`,
                        label: `${l[0].slice(0, 3) + l[1]}` + '\n' + `${x.rtntype}`,
                        options: {
                            filter: false,
                            sort: false,                           
                            setCellProps: value => {
                                return {
                                  style: {
                                   backgroundColor:   value === 'Filed' ? `#b7e1cd` : value === 'NotFiled' ? `#f4cccc` : (value === undefined) ? `#fff176` : `none`
                                  },
                                };
                              },                             
                        },
                        
                        
                    }
                    childCol.push(cCol)
                }
                
                cRow[ `${x.month}` + `${x.rtntype}`] = x.status == undefined ?`nill` : x.status
            })}
            
            })
            //console.log("cRow", cRow)
            setColumn(childCol)
            cRow['gstNo'] = d.data.gstNo
            cRow['tradeName'] = d.data.tradeName
            cRow['agent'] = d.data.agent
            cRow['status'] = d.data.status
            childRow.push(cRow)
            
           // console.log("childRow", childRow)
            setTotalData(d.data.totalRecords)
            setTotalPages(d.data.totalPages)
            setInitialLoad(false)
            setData(prevData => [...prevData, childRow[0]]);
            setDuplicateData(prevData => [...prevData, childRow[0]])
            }
            
            eventSource.onerror = (event) => {
            console.log(event.target.readyState)
            if (event.target.readyState === EventSource.CLOSED) {
                console.log('eventsource closed (' + event.target.readyState + ')')
            }
            eventSource.close();
            }
          
            setListening(true)
            setLoading(false)
        }

        return () => {
            eventSource.close();
            console.log("eventsource closed")
          }
      }, [pageIndex, pageSize]);

    const updateRowData = useCallback((newData) => {
    setData(newData); 
    }, []);

    const handleTextInputChange = event => {
        setPeriodData(event.target.value);
      };
    
      const onReset=()=>{    
        setPeriodData(MenuList[0].value);
        setGstData('')  
        //setListening(false)
        //setPageIndex(0)  
        setIsLoading(false)
        updateRowData(duplicateData) 
      }
    
      const handleGstInputChange = event => {
          setGstData(event.target.value);
      };
    
      const getSearchedDetails=async()=>{   
        setLoading(true)
        setSearch(true)
        if(gstData.length > 0){
            let data = {
              gstNo: gstData,
              year: periodData
            }
            await DataServices.getSearchList(data).then((res)=>{
              let val = res && res.data && res.data.data; 
              let cRow = {}    
              val && Object.entries(val.returns).map(([key,value],i)=>{
                {value.length>0 && value.map((x, y)=>{
                    cRow[ `${x.month}` + `${x.rtntype}`] = x.status == undefined ?`nill` : x.status
                })}
              })
                cRow['gstNo'] = val.gstNo
                cRow['tradeName'] = val.tradeName
                cRow['agent'] = val.agent
                cRow['status'] = val.status
                 updateRowData([cRow])
            }).catch((e)=>{
              console.log("err", e)
            })
        }
        setLoading(false)
      }


      const options = {
        serverSide: true,
        filter: false,
        download: false,
        filterType: "dropdown",
        responsive: "standard",
        selectableRows: false, 
        page,
        textLabels: {
            body: {
                noMatch: loading ?
                    <CircularProgress color='secondary' size={40} /> :
                    'No data found',
              /*   toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`,
                loading: pageSize - 1 > data.length  ? 'Loading...' : '', */
            }
        },            
        onSearchChange: (searchText) => {
            //console.log("search: " + searchText);
            if(searchText == null){
                setIsLoading(false)
                setData(duplicateData)
            }
            else{
                if(duplicateData.length > 1 ){
                    setIsLoading(true)
                    const res = duplicateData.filter(obj => Object.values(obj).some(val => val.toUpperCase().includes(searchText.toUpperCase())));
                    setData(res)
                }
            }          
              
        },

        pagination: true,
        jumpToPage: true,
        rowsPerPage: pageSize,
        rowsPerPageOptions: [10, 25, 50, 100],
        count: totalData,
        jumpToPageOptions: {
            rowsPerPage: pageSize, // The number of rows per page to use for the page count calculation
            maxPages: totalPages, // The maximum number of page numbers to show in the dropdown menu
            pagination: true, // Whether or not to use the built-in pagination component for the table  
          },
        // jumpToPageOptions: [5, 10, 15, 20, 25],
        // paginationRowsPerPageOptions: [5, 10, 15, 20, 25, 50, 100],
        onChangePage: (page, table) => {
          //console.log("New page:", page);
          setPage(page)
          setListening(false)
          setPageIndex(page)
          setData([]);
          setLoading(true)
        },
        onChangeRowsPerPage: (size)=>{
            //console.log("size:", size);
            setListening(false) 
            setPageSize(size)
        },
        customRowRender: (value,  dataIndex, rowIndex) => {
          const isLastRow = (data.length < pageSize) && (rowIndex === (data.length - 1));

          if(isLastRow && !isLoading){
            return (
              <tr style={{maxHeight: `20%`}}>
                <td colSpan={1} style={{ textAlign: `center`,fontSize: `18x`, fontFamily: `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`,
              }}>
                  <div style={{ display: `flex`, flexDirection: `row`, alignItems:`center`, padding: `10px`}}>
                  <CircularProgress color='secondary' size={35}/>
                  <p style={{marginTop: `6px`}}>Loading</p>
                  </div>
                 
                </td>
              </tr>
            );
          }
          return (
            <>
            <tr style={{minHeight: `50%`}}>
              {value.map((cellData, cellIndex) => (
                <td key={cellIndex} style={{ backgroundColor:   cellData === 'Filed' ? `#b7e1cd` : cellData === 'NotFiled' ? `#f4cccc` : `none`, textAlign: `center`,
                fontSize: `15px`, fontFamily: `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`,
                borderTop: `1px solid lightgray`}}>
                  {cellData}
                </td>
              ))}         
            </tr>
            </>
          );
        },
        // onDownload: (buildHead, buildBody, columns, data) => {
        //     //return "\uFEFF" + buildHead(columns) + buildBody(data); 
        //     // DataServices.getCsv().then((res)=>{
        //     //   console.log(res)
        //     //   setDownloadStatus(true)
        //     //   }).catch((e)=>{
        //     //   console.log("err", e)
        //     // })
        //     setDownloadStatus(true)
        // },
        customToolbar: () => {
            return (
                <CustomToolbarComponent count={count}/>
            );
        }
      };

      const SearchBar=()=>{
        return(
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4} md={3}>
                            <TextField
                              id="outlined-select-currency"
                              select
                              fullWidth
                              label="Select"
                              value= {periodData}
                              onChange= {handleTextInputChange}
                          
                            >
                              {MenuList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} sm={4} md={6}>
                            <TextField 
                            label="Enter Gst Number" 
                            value= {gstData}
                            onChange= {handleGstInputChange}
                            fullWidth/>
                          </Grid>
                          <Grid item xs={12} sm={4} md={3} style={{ display: `flex`, flexDirection: `row`, justifyContent: `space-evenly`}}>
                              <Button variant="contained" onClick={()=>getSearchedDetails()} >Search your gst</Button>
                              <Button variant="contained" color="secondary"onClick={()=> onReset()} >Reset</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>        
        );
    }
    
    
    const isLoadingComponent =()=>{
        return(
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid Item style={{alignContent: `center`, justifyContent: `center`}}>
              <CircularProgress />
              {/* <p style={{color: theme.palette.primary.main, textAlign: `center`, 
              padding: `10px`, marginLeft: `-60px`}}>{msg}</p> */}
              </Grid>
            </Grid>
        );
      }

    useEffect(()=>{
      console.log('isLoading', isLoading)
    },[isLoading])


      return(
        <React.Fragment>
            {SearchBar()}<br />

            {data && data.length == 0 || loading ?
            (
                isLoadingComponent()
            ) :
            (
                <MUIDataTable
                title={"Annual Filing"}
                data={data}
                columns={column}
                options={options}
                >
                   
                  {/* {isLoading &&(
                     <TableBody>
                     <TableRow>
                       <TableCell >
                       <CircularProgress />
                       </TableCell>
                     </TableRow>
                   </TableBody>
                  )} */}
                </MUIDataTable>
            )}

            

            
      </React.Fragment>
      );

      
    

}

export default FileingComponent


