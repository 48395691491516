import React,{useState, useEffect, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import Download from '@mui/icons-material/Download';
import DataServices from '../../Services/DataServices';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '../../Constants/MenuList';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CustomIconComponent from './CustomIconComponent';


function NewGst(){
    const [records, setRecords] = useState([]);
    const [data, setData] = useState([]);
    const initialColumn = [
        {
          name: "s.no",
          label: "S/No",
          options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return(
                  <span>{tableMeta.rowIndex + 1}</span>
                )
            }
          }
        },
        {
            name: "gstNo",
            label: "GST No",
            options: {
            filter: true,
            sort: true
            }
        },
        {
            name: "tradeName",
            label: "Trade Name",
            options: {
            filter: true,
            sort: true
            }
        },
        {
            name: "agent",
            label: "Agent",
            options: {
            filter: true,
            sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
            filter: true,
            sort: true
            }
        },
      ]
    const [column, setColumn] = useState(initialColumn);
    const [count, setCount] = useState(0);
    const [periodData, setPeriodData] = useState(MenuList[0].value)
    const [gstData, setGstData] = useState("");
    const [tablePage, setTablePage] = useState(0);
    const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(()=>{
        getApiCount();
        getTableData();
    },[])

    const getApiCount=async()=>{
        await DataServices.getCount().then((res)=>{
        let val = res && res.data && res.data.data && res.data.data.count;
        setCount(val)
        }).catch((e)=>{
        console.log("err", e)
        })
    }

    const updateTableData = () => {
        const startIndex = tablePage * tableRowsPerPage;
        const endIndex = startIndex + tableRowsPerPage;
        const dta = records.slice(startIndex, endIndex);
        setData(dta)
    };

    useEffect(() => {
        updateTableData();
      }, [tablePage, tableRowsPerPage, records]);

    const getTableData=async()=>{
      await DataServices.getCsvTableData().then((res)=>{
        let val = res && res.data && res.data.data; 
        let childCol = [...column] 
        let childRow = []  
        let valid = records && records.length == 0;
        val.length>0 && val.map((a, b)=>{  
            let cRow = {}; 
           {a && a.returns && Object.entries(a.returns).map(([key,value],i)=>{
                {value.length>0 && value.map((x, y)=>{
                    if(initialLoad && b==0 && valid){
                        let l = x.month.split(' ')
                        let cCol = {
                            name:  `${x.month}` + `${x.rtntype}`,
                            label: `${l[0].slice(0, 3) + l[1]}` + '\n' + `${x.rtntype}`,
                            options: {
                                filter: false,
                                sort: false,                           
                                setCellProps: value => {
                                    return {
                                      style: {
                                       backgroundColor:   value === 'Filed' ? `#b7e1cd` : value === 'NotFiled' ? `#f4cccc` : (value === undefined) ? `#fff176` : `none`
                                      },
                                    };
                                  },                             
                            },
                            
                            
                        }
                        childCol.push(cCol)
                    }
                    cRow[ `${x.month}` + `${x.rtntype}`] = x.status == undefined ?`nill` : x.status
                })}
            })}
            cRow['gstNo'] = a.gstNo
            cRow['tradeName'] =a.tradeName
            cRow['agent'] = a.agent
            cRow['status'] = a.status
            childRow.push(cRow)
        })
        setColumn(childCol)
        setRecords(childRow)
        setInitialLoad(false)
      }).catch((e)=>{
      console.log("err", e)
      })
  }


  const handleTextInputChange = event => {
    setPeriodData(event.target.value);
  };

  const onReset=()=>{    
    setPeriodData(MenuList[0].value);
    setGstData('')  
    getTableData();
    //setListening(false)
    //setPageIndex(0)  
  }

  const updateRowData = useCallback((newData) => {
    setData(newData); 
    }, []);

  const handleGstInputChange = event => {
      setGstData(event.target.value);
  };

  const getSearchedDetails=async()=>{   
    if(gstData.length > 0){
        let data = {
          gstNo: gstData,
          year: periodData
        }
        setRecords([])
        await DataServices.getSearchList(data).then((res)=>{
          let val = res && res.data && res.data.data; 
          let cRow = {}    
          val && Object.entries(val.returns).map(([key,value],i)=>{
            {value.length>0 && value.map((x, y)=>{
                cRow[ `${x.month}` + `${x.rtntype}`] = x.status == undefined ?`nill` : x.status
            })}
          })
            cRow['gstNo'] = val.gstNo
            cRow['tradeName'] = val.tradeName
            cRow['agent'] = val.agent
            cRow['status'] = val.status
             setRecords([cRow])
        }).catch((e)=>{
          console.log("err", e)
        })
    }
  }

  const SearchBar=()=>{
    return(
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sm={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={3}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          fullWidth
                          label="Select"
                          value= {periodData}
                          onChange= {handleTextInputChange}
                      
                        >
                          {MenuList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={4} md={6}>
                        <TextField 
                        label="Enter Gst Number" 
                        value= {gstData}
                        onChange= {handleGstInputChange}
                        fullWidth/>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} style={{ display: `flex`, flexDirection: `row`, justifyContent: `space-evenly`}}>
                          <Button variant="contained" onClick={()=>getSearchedDetails()} >Search your gst</Button>
                          <Button variant="contained" color="secondary"onClick={()=> onReset()} >Reset</Button>
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>        
    );
}


const isLoadingComponent =()=>{
    return(
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid Item style={{alignContent: `center`, justifyContent: `center`}}>
          <CircularProgress />
          {/* <p style={{color: theme.palette.primary.main, textAlign: `center`, 
          padding: `10px`, marginLeft: `-60px`}}>{msg}</p> */}
          </Grid>
        </Grid>
    );
}

const handleTableChangePage = (page) => {
    setTablePage(page);
  };
  
  const handleTableChangeRowsPerPage = (event) => {
    setTableRowsPerPage(parseInt(event, 10));
    setTablePage(0);
  };

  const handleCallback = (datafn) => {
        if(datafn){
            getTableData();
        }
  };

const options = {
    serverSide: false,
    filter: false,
    download: false,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: false, 
    pagination: true,
    jumpToPage: true,
    rowsPerPage: tableRowsPerPage,
    page: tablePage,
    count: records.length,
    jumpToPageOptions: {
        rowsPerPage: tableRowsPerPage, // The number of rows per page to use for the page count calculation
        // maxPages: totalPages, // The maximum number of page numbers to show in the dropdown menu
        pagination: true, // Whether or not to use the built-in pagination component for the table  
    },
    onChangePage: (page, table) => {
        handleTableChangePage(page)
    }, 
    onChangeRowsPerPage: (size)=>{
        handleTableChangeRowsPerPage(size)
    },
    customToolbar: () => {
        return (
            <CustomIconComponent count={count} callBackFn={handleCallback}/>
        );
    }
}


    return(
        <React.Fragment>
        {SearchBar()}<br />

        {records && records.length == 0 ?
        (
            isLoadingComponent()
        ) :
        (
            <MUIDataTable
            title={"Annual Filing"}
            data={records}
            columns={column}
            options={options}
            />
        )}
        </React.Fragment>
    );

}

export default NewGst