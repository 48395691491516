import { ListItemButton, ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';

const SidebarItem = ({ item }) => {
  let location = useLocation();
  const [pathName, setPathName] = useState(location.pathname);
  useEffect(() => {
   //console.log('lll', location);
   setPathName(location.pathname)
  }, [location]);

    return (
        item.sidebarProps && item.path ? (
          <ListItemButton
            component={Link}
            to={item.path}
            sx={{
              "&: hover": {
                backgroundColor: colorConfigs.sidebar.hoverBg
              },
              backgroundColor:  pathName == item.path ?  colorConfigs.sidebar.activeBg : `none` ,
              paddingY: "12px",
              paddingX: "24px",
              borderRadius: `25px`
            }}
          >
            <ListItemIcon sx={{
              color: colorConfigs.sidebar.color
            }}>
              {item.sidebarProps.icon && item.sidebarProps.icon}
            </ListItemIcon>
            {item.sidebarProps.displayText}
          </ListItemButton>
        ) : null
      );
}

export default SidebarItem;