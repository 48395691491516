import React,{useState, useEffect, useRef, useCallback} from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const initialCol = [
    {
      headerName: 'BASIC DETAILS',
      children: [
        { field: 'gstNo', width: 180, maxWidth: 200 },
        { field: 'tradeName', width: 200, maxWidth: 200},
        { field: 'agent', width: 120, maxWidth: 200},
        { field: 'status' ,  width: 100, maxWidth: 200 },
      ],
    },
  ]
  
function NewPagination(){
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(initialCol);
  const [pageNumber, setPageNumber] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(500);

  const gridRef = useRef(null);

  const getRowNodeId = (data) => data.gstNo;
  
  const fetchData = () => {
    setLoading(true);
    const eventSource = new EventSource(`https://services1.wegosecure.com/sit/basf/api/mygst/gst/returns?pageNo=${pageNumber}&pageSize=10`);
    eventSource.onmessage = (event) => {
      const d = JSON.parse(event.data);
      const rowDataObj = d.data;
      //const lastRow = 10;
      setRowData(prevRowData => prevRowData.concat(rowDataObj));
      setLoading(false);
      gridApi.hideOverlay();
      /* if (lastRow) {
        eventSource.close();
      } */
    };
    eventSource.onerror = () => {
      setLoading(false);
      gridApi.showNoRowsOverlay();
      eventSource.close();
    };
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.showLoadingOverlay();
      fetchData();
    }
  }, [pageNumber]);

  const onGridReady = (params) => {
    console.log(params, params.startRow, params.pageSize)
    setGridApi(params.api);
   
    params.api.setDatasource({
      getRows: (params) => {
        console.log("getRows", params)
        if(params.startRow){
          setPageNumber(params.startRow);
        }
        
        /* if(rowData.length == 0){
          setPageNumber(0);
        }
        else{
          setPageNumber((prev) => prev + 1);
        } */
        
        
       // setPageNumber(params.startRow / 10);
      },
      pageSize: 10,
      rowCount: null,
    });
  };

  const onBodyScroll = (event) => {
    const bottomReached = event.api.getLastDisplayedRow() === event.api.getDisplayedRowCount();
    console.log(bottomReached)
    const gridDiv = document.querySelector('.ag-root-wrapper-body');
    const isBottom = gridDiv.scrollTop + gridDiv.clientHeight === gridDiv.scrollHeight;
    const displayedRowCount = gridApi.getDisplayedRowCount();
    console.log("displayedRowCount", displayedRowCount)
    let valid =gridRef &&  gridRef.current &&  gridRef.current.props &&  gridRef.current.props.rowData.length > 0
    console.log("valid", valid)
    //let valid =gridApi &&  gridApi.current &&  gridApi.current.props &&  gridApi.current.props.rowData.length > 0
    if(displayedRowCount > 0 && valid && isBottom){
      setPageNumber((prev) => prev + 1);
    }
    else{
      setPageNumber(0);
    }
  };

  function getBottomVisibleRow(gridApi) {
    const gridElement = gridApi.gridCore.eBodyViewport;
    const scrollPosition = gridElement.scrollTop;
    const rowHeight = gridApi.getDisplayedRowAtIndex(0).rowHeight;
    const rowCount = gridApi.getDisplayedRowCount();
    const viewportHeight = gridElement.offsetHeight;
    const lastVisibleRowIndex = Math.ceil((scrollPosition + viewportHeight) / rowHeight) - 1;
    return Math.min(lastVisibleRowIndex, rowCount - 1);
  }

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: "500px", width: "600px" }}
    >
      <AgGridReact
        columnDefs={columnDefs}
        ref={gridRef}
        rowData={rowData}
        rowModelType="infinite"
        cacheBlockSize={10}
        getRowNodeId={getRowNodeId}
        onGridReady={onGridReady}
        onBodyScroll={onBodyScroll}
        overlayLoadingTemplate={loading ? "Loading..." : null}
      />
    </div>
  );
}

export default NewPagination;