import React,{useState, useEffect, useMemo, useRef, useCallback} from 'react';
import { Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DataServices from '../../Services/DataServices';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import CreateOrEditFileing from './CreateOrEdit';
import ToastController from '../../Controller/ToastController/ToastController';

function AnnualFilling(){
  const [data, setData] = useState([]);
  const initialColumn = [
      {
        name: "s.no",
        label: "S/No",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return(
                <span>{tableMeta.rowIndex + 1}</span>
              )
          }
        }
      },
      {
        name: "gstNo",
        label: "Gst No",
        options: {
        filter: true,
        sort: true
        }
     },
      {
          name: "backAgent",
          label: "Back Agent",
          options: {
          filter: true,
          sort: true
          }
      },
      {
          name: "clientName",
          label: "Client Name",
          options: {
          filter: true,
          sort: true
          }
      },
      {
          name: "salesAgent",
          label: "Agent",
          options: {
          filter: true,
          sort: true
          }
      },
      
    ]
  const [column, setColumn] = useState(initialColumn);
  const [open, setOpen]= useState(false);
  const [successBox, setSuccessBox]  = useState(false);
  const [toastMsg, setToastMsg]= useState({
    msg: ``,
    status: ``
  });
  const [loading, setLoading]= useState(false);
  useEffect(()=>{
    getList();
  },[])

  const getList=async()=>{
    setLoading(true)
    await DataServices.getUserList().then((res)=>{
      console.log("res", res)
      let response = res && res.data && res.data.data;
      setData(response); 
    })
    setLoading(false)
  }


  
const options = {
  serverSide: false,
  filter: false,
  search: false,
  print: false,
  viewColumns: false,
  download: false,
  filterType: "dropdown",
  responsive: "standard",
  selectableRows: false, 
  pagination: true,
  jumpToPage: true,
  count: data.length,
}


const isLoadingComponent =()=>{
  return(
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid Item style={{alignContent: `center`, justifyContent: `center`}}>
        <CircularProgress />
        </Grid>
      </Grid>
  );
}

  const onClose =()=>{
    setLoading(false)
    setOpen(false)
  }

  const onSave=async(e)=>{
    setLoading(true)
    await DataServices.saveUser(e).then((res)=>{
      console.log("res", res)
      setSuccessBox(true)
      setToastMsg((prevState) => ({
        ...prevState,
        msg: `Created Successfully`,
        status: 'success'
     }));
     getList();
    }).catch((e)=>{
      console.log(e)
      setSuccessBox(true)
      setToastMsg((prevState) => ({
        ...prevState,
        msg: `unable to create, Please try after sometime.`,
        status: 'error'
     }));
    })
    onClose();
  }

  const handleClose = () => {
    setSuccessBox(false)
  };

    return(
      <React.Fragment>
          <Grid container 
           spacing={2}
           direction="row"
           justifyContent="space-between"
           alignItems="center">
            <Grid item xs={11} sm={11} md={11}>
              <Typography variant='h6'>
                Client Details 
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
                <AddIcon color='primary' onClick={()=> setOpen(true)}/>
            </Grid>
          </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            { (loading && data && data.length == 0) ?(isLoadingComponent()) :
              (
                  <MUIDataTable
                  data={data}
                  columns={column}
                  options={options}
                  />
              )}
          </Grid>
        </Grid>
        {open && <CreateOrEditFileing open={open} onSave={onSave} onClose={onClose} data={{}}
      mode={'Create'}/>}
        {successBox && (
                <ToastController
                    isOpen={successBox}
                    onClose={handleClose}
                    message={toastMsg.msg}
                    status={toastMsg.status} />
            )}
      </React.Fragment>
    );

}


export default AnnualFilling;


/**
  {open && <CreateOrEditFileing open={open} onSave={onSave} onClose={onClose} data={{}}
      mode={'Create'}/>}
 */